import server from "../axios.config.js";

// 获取用户列表
export const getListAll = function (size, current) {
    return server({
        url: `http://110.87.103.59:18086/farmfriend/plantFile/selectPageList?current=${current}&size=${size}`
    })
}

// 获取省
export const provinceList = function () {
    return server({
        url: "http://110.87.103.59:18086/commonservice/province/getList"
    })
}
// 获取市
export const cityList = function (provinceCode) {
    return server({
        url: `http://110.87.103.59:18086/commonservice/city/getList?provinceCode=${provinceCode}`
    })
}
// 获取县
export const countyList = function (cityCode) {
    return server({
        url: `http://110.87.103.59:18086/commonservice/county/getList?cityCode=${cityCode}`
    })
}

// 根据省市县查询地块
export const getSelectListAll = function(county) {
    return server({
        url: `http://110.87.103.59:18086/farmfriend/land/selectListAll?county=${county}`
    })
}

// 根据地块查询作物
export const getByLandIds = function(landIds) {
    return server({
        url: `http://110.87.103.59:18086/farmfriend/crop/getByLandIds?landIds=${landIds}`
    })
}

// 根据地块和作物查询品种
export const getLandsCropTypeList = function(landIds, cropId) {
    return server({
        url: `http://110.87.103.59:18086/farmfriend/cropType/getLandsCropTypeList?cropId=${cropId}&landIds=${landIds}`
    })
}

// 新增种植档案
export const postPlantFile = function(param) {
    return server({
        url: "http://110.87.103.59:18086/farmfriend/plantFile/save",
        method: "POST",
        headers: {
            "content-type": "application/json"
        },
        data: param
    })
}

// 根据id删除档案
export const postDelete = function(data) {
    return server({
        url: "http://110.87.103.59:18086/farmfriend/plantFile/delete",
        method: "POST",
        headers: {
            "content-type": "application/json"
        },
        data: data
    })
}

// 根据id查询
export const getById = function(id) {
    return server({
        url: `http://110.87.103.59:18086/farmfriend/plantFile/getById?id=${id}`
    })
}

// 查询地块
export const LandById = function(id) {
    return server({
        url: `http://110.87.103.59:18086/farmfriend/land/getById?id=${id}`
    })
}

// 编辑接口
export const plantFileUpdate = function(param) {
    return server({
        url: `http://110.87.103.59:18086/farmfriend/plantFile/update`,
        method: "POST",
        headers: {
            "content-type": "application/json"
        },
        data: param
    })
}